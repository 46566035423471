
import  Store  from '../store/index';
import axios from '../axios/axios';
export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        // eslint-disable-next-line no-empty-pattern
        getAll({},url) {
            return new Promise((resolve, reject) => {
                axios.request(
                    {
                        method: 'get',
                        url: url,
                        headers: {
                           Authorization: `Bearer ${Store.state.token}`
                        }
                    },
                    ).then((res) => {
                    resolve(res);
                    }).catch((e) => {
                        console.log('tag', e);
                    reject(e.data);
                });
            })
        },
        
        // eslint-disable-next-line no-empty-pattern
        post({},data) {
            return new Promise((resolve, reject) => {
                axios.request(
                    {
                        method: 'post',
                        url: data.url,
                        data:data.form,
                        headers: {
                           Authorization: `Bearer ${Store.state.token}`
                        }
                    }).then((res) => {
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                });
            }); 
        },
        
        getById(url, id) {
            return new Promise((resolve, reject) => {
                axios.get(`${url}/${id}`).then((res) => {
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                });
            }) 
        },
        
        // eslint-disable-next-line no-empty-pattern
        updata({}, url) {
            return new Promise((resolve, reject) => {
               axios.request(
                    {
                        method: 'put',
                        url: url,
                        headers: {
                           Authorization: `Bearer ${Store.state.token}`
                        }
                    }).then((res) => {
                resolve(res);
                }).catch((e) => {
                    reject(e);
                });
            }) 
        },
        delete({ state },url) {
            return new Promise((resolve, reject) => {
                 axios.request(
                    {
                        method: 'delete',
                        url: url,
                        headers: {
                           Authorization: `Bearer ${Store.state.token}`
                        }
                    }).then((res) => {
                        resolve(res);
                        console.log('tag', state);
                }).catch((e) => {
                    reject(e);
                });
            }) 
        },
    }
}
