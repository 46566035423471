import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { requiresGuest: true },
    component: () => import(/* webpackChunkName: "Auth" */ '../layout/Auth.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Admin" */ '../layout/Admin.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Users" */ '../views/Home.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/policies',
        name: 'Policies',
        component: () => import(/* webpackChunkName: "policies" */ '../views/Policies/index.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "Users" */ '../views/Users/index.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/companies',
        name: 'Companies',
        component: () => import(/* webpackChunkName: "Companies" */ '../views/Companies/index.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/schemes',
        name: 'Schemes',
        component: () => import(/* webpackChunkName: "Schemes" */ '../views/Schemes/index.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/reports',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "Reports" */ '../views/Report/index.vue'),
        meta: { requiresAuth: true }
      },
       {
        path: '/schemes-reports',
        name: 'SchemesReports',
        component: () => import(/* webpackChunkName: "SchemesReports" */ '../views/Report/SchemesReports.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: () => import(/* webpackChunkName: "Notifications" */ '../views/Notifications/index.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('tag', store.getters['loggedIn'])
    if (store.getters['loggedIn']) {
      if (to.path == "/login") next(false);
      else next();
    } else next("/login");
  } else if(to.matched.some(record => record.meta.requiresGuest)){
      if(store.getters['loggedIn']) next(false);
      else next();
  } else {
      next();
  }
});
export default router
