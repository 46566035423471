import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
    rtl: false,
    theme: {
        themes: {
          light: {
            primary: '#2185DD',
            secondary: '#424242',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
          },
          dark: {
            primary: colors.blue.lighten3,
          },
        },
      },
       
});
