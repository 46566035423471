export default {
    namespaced: true,
    state: {
       snackBars: []
    },
    mutations: {
        setSnackBar(state,payload) {
            // state.snackBars = [payload];
            state.snackBars = state.snackBars.concat(payload);
        }
    }, 
    actions: {
        setSnackBars({ commit }, payload) {
            payload.showing = true;
            payload.color = payload.color || 'success';
            // payload.timeout = 0;
            commit('setSnackBar', payload);
        }
      },
}