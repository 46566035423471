import axios from "axios";


const instance = axios.create({
  // baseURL: "https://2ba9be765ccb.ngrok.io/api",
  baseURL: "https://app.mhmins.com/api",
 
  timeout: 15000,
  headers: {
    accept: "application/json",
    "content-type": "application/json"
  }
});

export default instance;