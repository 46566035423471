<template>

      <router-view/>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Questrial");

body {
  font-family: "Questrial" !important;
}

</style>
