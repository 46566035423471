import Vue from 'vue'
import Vuex from 'vuex'
import Api from './api'
import snackBar from './snackBar';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || null,
    user: JSON.parse(localStorage.getItem('user')) || null
  },
  getters: {
    loggedIn(state) {
        return !!state.token;
    },
    isAdmin(state) {
      if (state.user.type == "Super-Admin") return true;
      else return false;
    }
},
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    clearToken(state) {
      state.token = null;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  modules: {
    Api,
    snackBar
  }
})
